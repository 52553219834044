.modal--container {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
    z-index: 1300;
  }
  
  .modal--content {
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    background-color: #fefefe;
    position: relative;
    font-size: 1.5rem;
    top: 2vh;
  }
  
  .modal--close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .modal--close:hover,
  .modal--close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal--text {
    margin-top: 20px;
  }

  @media only screen and (max-width : 800px) {
    .modal--content {
      width: 60%;
      font-size: 1.2rem;
      top: 7vh;

    }
  }

  @media only screen and (max-width : 500px) {
    .modal--content {
      width: 70%;
      font-size: 1rem;
      top: 12vh;
    }
  }