.aboutus--container {
    width: 100%;
}

.aboutus--banner {
    width: 100%;
    object-fit: cover;
}

.aboutus--bannerText {
    color:#404b1f;
    position: absolute;
    z-index: 1000;
}

.aboutus--bannerContent {
    width: 100%;
    position: relative;
}

.aboutus--weAreImage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    text-align: left;
    position: relative;
    top: 5vh;

}
.aboutus--weAreImage h1 {
    color: #dd7418;
    margin-left: auto;
}

.aboutus--weAreImage img {
    max-width: 30%;
    position: relative;
}

.aboutus--description {
    font-size: 1.2rem;
    max-width: 80%;
}

.aboutus--olori {
    position: relative;
    font-family: "Romanesco", cursive;
    color: #404b1f;
    letter-spacing: 2px;
    font-size: 3.5rem;
}

.aboutus--buttons {
    display: flex;
    justify-content: center;
    gap: 2vw;
    position: relative;
}

@media only screen and (max-width : 1172px) {
    .aboutus--weAreImage {
        flex-direction: column-reverse;
    }
    .aboutus--weAreImage {
        text-align: center;
    }
    .aboutus--description {
        max-width: none;
    }
}

@media only screen and (max-width : 1000px) {
    .aboutus--bannerText {
        font-size: 30px;
    }

}

@media only screen and (max-width : 700px) {
    .aboutus--bannerText {
        font-size: 25px;
    }
    .aboutus--weAreImage h1 {
        font-size: 2.2rem;
    }
    .aboutus--olori {
        font-size: 2rem;
    }
    .aboutus--buttons {
        flex-direction: column;
    }
    .aboutus--weAreImage img {
        max-width: 50%;
    }
}

@media only screen and (max-width : 275px) {
    .aboutus--olori {
        font-size: 1.5rem;
    }
}