.home--container img{
    width: 100%;
    object-fit: cover;
    height: 100%;
    overflow: hidden;
}

.home--container {
    position: relative;
    top: -5vh;
    width: 100%; 
    height: 100vh; 
    overflow: hidden;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.home--container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
  overflow: hidden;
}

.home--imageTextContainer {
    position: absolute;
    top: 13%;  
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 1400;
}

.home--text {
    display: flex;
    flex-direction: column;
}

.home--text h1 {
    display: flex;
    flex-direction: column;
    color: #dd7418;
}

.home--olori {
    position: relative;
    top: 9vh;
    font-family: "Romanesco", cursive;
    color: white;
    letter-spacing: 2px;
    text-shadow: 2px 0px 5px #404b1f ;
    font-size: 3.5rem;
  }

/* CSS */
.button-74 {
  background-color: white;
  border: 2px solid #404b1f;
  border-radius: 30px;
  box-shadow: #404b1f 4px 4px 0 0;
  color:#404b1f;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-74:hover {
  background-color: #e6e3e1;
  color:#404b1f;
}

.button-74:active {
  box-shadow: #B20000 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-74 {
    min-width: 120px;
    padding: 0 25px;
  }
}


@media only screen and (max-width : 1000px) {
  .home--container {
    position: relative;
    overflow: hidden;
  }

  .home--container-overlay::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    overflow: hidden;
}
}

@media only screen and (max-width : 600px) {
  .home--olori {
    top: 8vh;
  }
}

@media only screen and (max-width : 450px) {
  .home--text h1 {
    font-size: 2rem;
  }
  .home--olori {
    font-size: 2.5rem;
    top: 6vh;

  }
}

@media only screen and (max-width : 375px) {
  .home--text h1 {
    font-size: 1.8rem;
  }
  .home--olori {
    font-size: 2.2rem;
    top: 4vh;

  }
}