.banner--img {
    width: 100%;
    object-fit: cover;
    height: auto;
}

.banner--text {
    color:#404b1f;
    position: absolute;
    z-index: 1000;
}

.banner--content {
    overflow: hidden;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media only screen and (max-width : 1000px) {
    .banner--text {
        font-size: 30px;
    }

}

@media only screen and (max-width : 700px) {
    .banner--text {
        font-size: 25px;
    }
}