.ourservices--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.ourservices--banner {
    width: 100%;
    object-fit: cover;
}

.ourservices--bannerText {
    color:#404b1f;
    position: absolute;
    z-index: 1000;
}

.ourservices--bannerContent {
    width: 100%;
    overflow: hidden;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ourservices--gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px; 
    width: 50%;
}

.image-container {
    position: relative; 
}

.ourservices--gridImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s ease;
    position: relative; 
    cursor: pointer;

}

.ourservices--imageText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#404b1f;
    font-size: 2rem;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.image-container:hover .ourservices--imageText {
    opacity: 1;
}

.ourservices--gridImg:hover {
    opacity: 0.3;
}

.modal-open {
    overflow: hidden;
}

.ourservices--accordionContainer {
    width: 50%;
    color: #404b1f;
}

.ourservices--accordion {
    background-color: #eee;
    cursor: pointer;
    color: #404b1f;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4;
    font-family: "Spectral SC", serif;
}
  
.ourservices--accordion:hover {
    background-color: #ccc; 
  }
  
  .ourservices--panel {
    padding: 0 18px;
    background-color: white;
    overflow: hidden;
    font-size: 1.2rem;
  }

  @media only screen and (max-width : 1050px) {
    .ourservices--gridContainer {
        width: 80%;
    }
    .ourservices--accordionContainer {
        width: 75%;
    }
  }

  @media only screen and (max-width : 700px) {
    .ourservices--imageText {
        font-size: 1.6rem;
    }
  }