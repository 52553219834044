.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: "Spectral SC", serif;}

  body, html {
    margin: 0;
    padding: 0;
  }