.navbar--container {
  width: 100%;
  display: flex;
  height: 130px;
}

.navbar--contents {
    position: sticky;
    top: 0;
    background: white;
    width: 100%;
    align-items: center;
    z-index: 1500;
    display: flex;
    height: 100%;
}


.navbar--links {
  display: flex;
  flex-direction: row;
  list-style: none;
  position: absolute;
  left: 15vw;
  font-size: 70%;
  gap: 1vw;
  margin: 0; 
}

  .navbar--links a {
    text-decoration: none;
    color:#404b1f;
  
  }

 .navbar--links a:visited {
  color:#404b1f;
}

.navbar--links .active{
  text-decoration: underline;
  text-decoration-thickness: 0.1em;
  text-underline-offset: 1vh;
}

.navbar--links a:hover {
  color: gray;
}

.navbar--bars {
  display: none;
}

.navbar--bars-dropdown {
  display: none;
}


@media only screen and (max-width : 1172px) {
  .navbar--links {
    left: 20vw;
  }
}


@media only screen and (max-width : 1000px) {
  .navbar--links li {
    display: none;
  }

  .navbar--bars {
    display: block;
    margin-left: auto;
    margin-right: 8vw;
  }

  .navbar--bars-dropdown {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    text-align: center;
    background-color: white;
    width: 100%;
    height: 40vh;
    color: #404b1f;
    z-index: 1500;
    border: 1px solid transparent; 
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  }

  .navbar--bars-dropdown-links {
    padding-left: 0;
  }

  .navbar--bars-dropdown-links li {
    list-style: none;
  }
  .navbar--bars-dropdown-links a {
    text-decoration: none;
    color: #404b1f;
  }
  .navbar--bars-dropdown-links a:visited {
    color: #404b1f;
  }
  .navbar--container {
    flex-direction: column;
  }
}