.contactus--container {
    width: 100%;
}

.contactus--formContainer {
    display: inline-flex;
    flex-direction: column;
}

.contactus--label {
    font-size: 0.6em;
    color:#404b1f;
}

.contactus--textLabel {
    width: 30vw;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.contactus--formContainer textarea {
    resize: none;
    height: 30vh;
}

.contactus--contactInfo {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 30%;
}

.contactus--contactInfo a {
    color: #404b1f;
}
.contactus--contactInfo p {
    font-size: 1.2rem;
}
.contactus--formAndText {
    display: flex;
    justify-content: center;
    gap: 15vw;
    color: #404b1f;
}

@media only screen and (max-width : 1000px) {
    .contactus--formAndText {
        flex-direction: column;
        align-items: center;
        gap: 5vh;
        text-align: center;
    }
    .contactus--contactInfo {
        text-align: center;
        width: 55%;
    }
    .contactus--formAndText input {
        width: 60vh;
    }
    .contactus--formAndText textarea {
        width: 60vh;
    }
    
}

@media only screen and (max-width : 800px) {
    .contactus--formAndText input {
        width: 50vh;
    }
    .contactus--formAndText textarea {
        width: 50vh;
}
}

@media only screen and (max-width : 698px) {
    .contactus--contactInfo {
        width: 70%;
    }
}
@media only screen and (max-width : 660px) {
    .contactus--formAndText input {
        width: 30vh;
    }
    .contactus--formAndText textarea {
        width: 30vh;
}
}

@media only screen and (max-width : 545px) {
    .contactus--contactInfo a {
        font-size: 80%;
    }
}

@media only screen and (max-width : 425px) {
    .contactus--formAndText input {
        width: 27vh;
    }
    .contactus--formAndText textarea {
        width: 27vh;
    }
    .contactus--contactInfo {
        text-align: center;
        width: 70%;
    }
}

@media only screen and (max-width : 350px) {
    .contactus--contactInfo a {
        font-size: 75%;
    }
}